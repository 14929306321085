<template>
  <div>
    <TopContent />
    <LffServices />
    <LffHardSkills />
    <LffSeparator />
    <LffSoftSkills />
    <LffAboutMe />
    <LffProjects />
    <LffPreFooter />
  </div>
</template>

<script>
import TopContent from "./landing/TopContent";
import LffServices from "./landing/LffServices";
import LffHardSkills from "./landing/LffHardSkills";
import LffSeparator from "./landing/LffSeparator.vue";
import LffSoftSkills from "./landing/LffSoftSkills.vue";
import LffAboutMe from "./landing/LffAboutMe.vue";
import LffProjects from "./landing/LffProjects.vue";
import LffPreFooter from "./landing/LffPreFooter.vue";

export default {
  name: "LffLanding",
  components: {
    TopContent,
    LffServices,
    LffHardSkills,
    LffSeparator,
    LffSoftSkills,
    LffAboutMe,
    LffProjects,
    LffPreFooter,
  },
};
</script>

<style></style>
