import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#FA2D2D",
        secondary: colors.pink.darken1,
        accent: colors.red,
        error: colors.red.accent3,
      },
      // dark: {
      //   primary: colors.blue.lighten3,
      // },
    },
  },
});
