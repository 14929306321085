export const hardSkills = {
  "Web Frontend": [
    "HTML",
    "CSS",
    "SCSS",
    "ReactJS",
    "VueJS",
    "Material Design (MUI / Vuetify)",
    "Jest",
    "ActionCable / AnyCable",
    "Bootstrap",
    "Web Push Notifications",
    "WebRTC / LiveStreaming",
  ],
  Mobile: [
    "Android (Java / Kotlin)",
    "iOS (Swift)",
    "CocoaPod",
    "Swift UI",
    "ReactiveX",
    "Retrofit",
    "React Native",
    "Jitsi Integration",
  ],
  "Web Backend & Tools": [
    "Ruby On Rails",
    "REST API",
    "GraphQL API (Apollo)",
    "Wordpress",
    "MySQL",
    "MongoDB",
    "Redis",
    "Elasticsearch",
    "OAuth / SSO",
    "Devise",
    "Sidekiq",
    "RSpec",
    "Factory Bot",
    "Git",
    "AWS S3",
  ],
};
