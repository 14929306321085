<template>
  <v-app-bar app color="transparent" flat>
    <div class="d-flex align-center">
      <router-link :to="{ name: 'landing' }">
        <v-img
          contain
          alt="Logo de La Fab Factory"
          class=""
          :src="require('../assets/images/logo.svg')"
          transition="scale-transition"
          max-width="80"
        />
      </router-link>
    </div>

    <v-spacer></v-spacer>
    <div class="mr-4 d-none d-sm-block">
      <v-btn
        color="grey darken-3"
        icon
        target="_blank"
        :href="`https://www.malt.fr/profile/davidfabreguette`"
      >
        <v-icon small>icon-malt</v-icon>
      </v-btn>
      <v-btn
        color="grey darken-3"
        icon
        target="_blank"
        :href="`https://twitter.com/LaFabFactory1`"
      >
        <v-icon>mdi-twitter</v-icon>
      </v-btn>
      <v-btn
        href="https://www.linkedin.com/in/david-fabreguette-8740b454/"
        color="grey darken-3"
        icon
      >
        <v-icon>mdi-linkedin</v-icon>
      </v-btn>
      <!-- <v-btn color="grey darken-3" icon>
        <v-icon>mdi-github</v-icon>
      </v-btn> -->
    </div>
    <div class="z-index-reset">
      <v-btn
        color="primary"
        elevation="0"
        href="https://calendly.com/la-fab-factory/30min"
      >
        <span class="d-none d-sm-inline">ME</span>
        CONTACTER
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",
};
</script>

<style lang="scss">
.v-app-bar {
  &.v-app-bar--is-scrolled {
    .v-toolbar__content {
      background: #ffffff8e;
      backdrop-filter: blur(7px);
    }
  }
  .v-toolbar__content {
    transition: all 0.5s ease-in-out;
  }
}
</style>
