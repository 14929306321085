import Vue from "vue";
import App from "./App.vue";
import "./scss/main.scss";
import vuetify from "./plugins/vuetify";
import vueGlide from "./plugins/vue-glide";
import vueAnimate from "./plugins/vue-animate";
import vueScrollReveal from "./plugins/vue-scroll-reveal";
import vueMasonry from "./plugins/vue-masonry";
import vueMoment from "./plugins/vue-moment";
import vueGTag from "./plugins/vue-gtag";
import vueRouter from "./plugins/vue-router";
Vue.config.productionTip = false;

new Vue({
  vuetify,
  vueGlide,
  vueAnimate,
  vueMasonry,
  vueMoment,
  vueScrollReveal,
  vueGTag,
  router: vueRouter,
  render: (h) => h(App),
}).$mount("#app");
