<template>
  <div class="services lff-wrapper py-8 d-flex justify-center align-center">
    <v-container class="narrowed">
      <v-row>
        <v-col cols="12" sm="4">
          <div>
            <h2 class="text-h6 whitish--text font-weight-light">
              À votre
              <span
                id="services"
                class="text-h1 mt-n5 whitish--text font-weight-bold"
              >
                service
              </span>
            </h2>
          </div>
        </v-col>
        <v-col cols="12" sm="8" class="d-flex justify-center align-center">
          <v-expansion-panels class="custom-panels" flat mandatory>
            <v-expansion-panel v-for="(service, i) in services" :key="i">
              <v-expansion-panel-header>
                <h3 class="text-h3">
                  <span class="service-number">0{{ i + 1 }}/</span>
                  {{ service.title }}
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                class="pa-0 white-space-pre"
                v-text="service.description"
              >
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { services } from "../../data/services";

export default {
  name: "LffServices",
  data() {
    return {
      services,
    };
  },
};
</script>
<style lang="scss">
.custom-panels {
  &.v-expansion-panels {
    .v-expansion-panel {
      color: #f7f7f7;
      background-color: transparent;
      .v-expansion-panel-header {
        padding: 0;
        border-top: 2px solid #f7f7f7;
        border-radius: 0;
      }
      .v-expansion-panel-header__icon i {
        // display: none;
        &:before {
          color: white;
        }
      }
      .v-expansion-panel-content {
        .v-expansion-panel-content__wrap {
          padding: 0;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.services {
  width: 100%;
  .service-number {
    font-size: 15px;
    vertical-align: top;
    top: -4px;
    position: relative;
    display: inline;
  }
}
</style>
