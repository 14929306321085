<template>
  <v-footer class="align-start py-10">
    <v-row>
      <v-col cols="12" class="pt-10">
        <div
          class="footer-wrapper d-flex flex-column justify-start align-center text-center"
        >
          <router-link :to="{ name: 'landing' }">
            <v-img
              contain
              :src="require('../assets/images/logo-dark.svg')"
              max-width="80"
              alt="Logo de la Fab Factory version sombre"
            />
          </router-link>
          <div class="white--text text-body-2">
            La Fab Factory — <a href="/">www.lafabfactory.fr</a> — ©{{
              currentYear
            }}
          </div>
          <div class="white--text text-body-2">
            <router-link :to="{ name: 'mentions-legales' }"
              >Mentions Légales</router-link
            >
            —
            <router-link :to="{ name: 'conditions-generales-de-vente' }">
              Conditions Générales de Vente
            </router-link>
          </div>
          <div>
            <v-btn
              color="white"
              icon
              target="_blank"
              :href="`https://www.malt.fr/profile/davidfabreguette`"
            >
              <v-icon small>icon-malt</v-icon>
            </v-btn>
            <v-btn
              color="white"
              icon
              target="_blank"
              :href="`https://twitter.com/LaFabFactory1`"
            >
              <v-icon>mdi-twitter</v-icon>
            </v-btn>
            <v-btn color="white" icon>
              <v-icon>mdi-linkedin</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "LffFooter",
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-footer {
  background: url("../assets/images/footer-bg.svg") no-repeat center;
  background-size: cover;
  background-position: top center;
  min-height: 250px;
  .footer-wrapper {
    gap: 10px;
    a {
      color: white;
      &:hover {
        color: #fa2d2d;
      }
    }
  }
}
</style>
