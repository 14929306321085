<template>
  <v-container class="wrapper mt-10 pt-10 px-4">
    <h1>Mentions Légales</h1>
    <p>
      Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour
      la confiance en l'économie numérique, il est précisé aux utilisateurs du
      site La Fab Factory l'identité des différents intervenants dans le cadre
      de sa réalisation et de son suivi.
    </p>
    <h2>Edition du site</h2>
    <p>
      Le présent site, accessible à l’URL www.lafabfactory.fr (le « Site »), est
      édité par :
    </p>
    <p>
      David Fabreguette — Dirigeant de la société La Fab Factory<br/>
      SIRET : 535 151 070 00021<br/>
      CODE APE : 6201Z<br/>
      FORME JURIDIQUE : EI
    </p>
    <h2>Hébergement</h2>
    <p>
      Le Site est hébergé par la société Netlify, situé 2325 3rd Street, Suite
      296, San Francisco, California 94107, (contact téléphonique ou email :
      +14156911573).
    </p>
    <h2>Directeur de publication</h2>
    <p>Le Directeur de la publication du Site est David Fabreguette.</p>
    <h2>Données personnelles</h2>
    <p>
      Le traitement de vos données à caractère personnel est régi par notre
      Charte du respect de la vie privée, disponible depuis la section "Charte
      de Protection des Données Personnelles", conformément au Règlement Général
      sur la Protection des Données 2016/679 du 27 avril 2016 («RGPD»).
    </p>
    <h2>Nous contacter</h2>
    <p>
      Par email :
      <v-img
        contain
        width="170"
        height="20"
        :src="require('../assets/images/contact.svg')"
      />
    </p>
  </v-container>
</template>

<script>
export default {
  name: "LffMentions",
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 70vh;
}
</style>
