import routes from "../routes";
import Vue from "vue";
import VueRouter from "vue-router";

const router = new VueRouter({
  mode: 'history',
  routes,
});

const TITLE_PREFIX = 'La Fab Factory'
const DEFAULT_TITLE = 'Artisants du numérique';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        // document.title = to.meta.title || DEFAULT_TITLE;
        document.title = `${TITLE_PREFIX} | ${to.meta.title || DEFAULT_TITLE}`;
    });
});

router.beforeEach((to, from, next) => {
  next();
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  // ...
});

Vue.use(VueRouter);

export default router;
