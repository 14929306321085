<template>
  <div class="soft-skills py-4 py-md-8 d-flex justify-center align-center">
    <v-container class="narrowed">
      <div class="text-left">
        <h2 class="text-h6 font-weight-light text-gradient">
          mes

          <span class="text-h1 mt-n5 font-weight-bold text-gradient"
            >soft skills</span
          >
        </h2>
      </div>
      <div class="">
        <v-row class="d-flex flex-row justify-end align-end">
          <v-col
            v-scroll-reveal.reset
            v-animate-css.once="'slideInDown'"
            cols="12"
            sm="4"
            class="d-flex flex-column justify-center align-center"
          >
            <v-img
              eager
              contain
              :src="require('../../assets/images/creative.svg')"
              max-height="250"
              alt="Illustration pour la soft skil 'Créatif'"
            />
            <h3 class="text-h5 grey--text text--darken-3">CRÉATIF</h3>
          </v-col>
          <v-col
            v-scroll-reveal.reset
            v-animate-css.once="'slideInUp'"
            cols="12"
            sm="4"
            class="d-flex flex-column justify-center align-center"
          >
            <v-img
              eager
              contain
              :src="require('../../assets/images/team-spirit.svg')"
              alt="Illustration pour la soft skil 'Esprit d'équipe'"
              height="250"
            />
            <h3 class="text-h5 grey--text text--darken-3 mt-3">
              ESPRIT D'ÉQUIPE
            </h3>
          </v-col>
          <v-col
            v-scroll-reveal.reset
            v-animate-css.once="'slideInDown'"
            cols="12"
            sm="4"
            class="d-flex flex-column justify-center align-center problem-solver-wrapper"
          >
            <v-img
              eager
              contain
              :src="require('../../assets/images/problem-solver.svg')"
              alt="Illustration pour la soft skil 'Persévérant'"
              max-height="250"
            />
            <h3 class="text-h5 grey--text text--darken-3">PERSÉVÉRANT</h3>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "LffSoftSkills",
  computed: {},
};
</script>

<style lang="scss" scoped></style>
