<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-img
          :src="require('../../assets/images/separator.svg')"
          min-height="50"
          alt=""
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LffSeparator",
};
</script>

<style></style>
