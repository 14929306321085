<template>
  <div
    class="main-container flex-column py-10 d-flex justify-center align-center"
  >
    <v-container class="pt-10 pt-md-0 narrowed">
      <v-row>
        <v-col
          cols="12"
          class="d-flex flex-md-row flex-column justify-center align-center"
        >
          <v-img
            contain
            class="mr-0 mr-md-4"
            width="80%"
            max-width="310"
            max-height="100%"
            v-animate-css.once="'slideInLeft'"
            :src="require('../../assets/images/profile-photo.png')"
            alt="Photo de David Fabreguette"
          />
          <!-- :src="require('../../assets/images/profile-photo.png')" -->
          <h1
            v-animate-css.once="'slideInRight'"
            class="main-h1 text-center d-flex flex-column justify-center justify-md-start align-center align-md-start"
          >
            <span class="main-title">David Fabreguette</span>
            <span class="text-h2">
              INGÉNIEUR<br />
              FULLSTACK
            </span>
            <span class="text-h1 font-weight-bold">
              web
              <span class="separator">{{ "&lt;/&gt;" }}</span>
              mobile
            </span>
          </h1>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      color="primary"
      @click="goDown"
      v-animate-css.once="'bounceIn'"
      class="mt-0 mt-md-10"
      fab
      elevation="0"
      title=""
    >
      <v-icon color="white">mdi-arrow-down</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "TopContent",
  data() {
    return {
      showButton: true,
    };
  },
  methods: {
    goDown() {
      const services = document.getElementById("services");
      if (services) {
        const boundingPosition = services.getBoundingClientRect();
        window.scrollTo({
          top: boundingPosition.top + window.scrollY - 160,
          left: 100,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  min-height: 90vh;
  width: 100%;
  background: url("../../assets/images/top-background.jpg") no-repeat center;
  background-size: cover;
}
.separator {
  position: relative;
  top: 4px;
}
.main-title {
  text-indent: -9999px; /* sends the text off-screen */
  background-image: url(../../assets/images/david-fabreguette.png); /* shows image */
  width: 280px; /* be sure to set height & width */
  height: 78.63px;
  white-space: nowrap;
  background-size: contain;
}
@media (max-width: 768px) {
  .main-container {
    min-height: 80vh;
  }
  .text-h1 {
    font-size: 40px !important;
  }
  .main-h1 {
    > span {
      display: block;
    }
  }
}
</style>
