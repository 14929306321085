export const projects = {
  ammy: {
    title: "Ammy Communauté",
    shortDescription:
      "Réseau social éthique et solidaire dédié aux personnes fragiles",
    functionalDescription: `
<p class="mb-1">
  Ammy communauté est un réseau social éthique et solidaire porté par Appliserv / Environnement. Dans la pratique, c'est :
</p>
<ul>
  <li>Un réseau social de partage et d’échanges</li>
  <li>Un outil pour suivre les évènements à côté de chez vous</li>
  <li>La possibilité d’échanger des services ou de s’entraider</li>
  <li>La possibilité de suivre l'activité des collectivités ou associations locales via des pages communautés</li>
</ul>
<p class="mt-1">
  Ce projet est soutenu par un grand nombre d'acteurs majeurs comme l'APF France Handicap, la CRESS Occitanie, BPI France ou le CHU de Nîmes (liste complète sur <a href="https://www.ammy.fr/communautes">ammy.fr/communautes</a>)
</p>
`,
    technicalDescription: `
    <p class="mb-1">
    <ul>
    <li>
    Application socle monolithique dévelopée en Ruby On Rails qui sert les données en API REST
    </li>
    <li>SPA VueJS</li>
    <li>Deux applications natives Android / iOS.
    </li></ul>
    </p>
    `,
    // partners: [
    //   "HelloAsso", une dizaine de département français (Gard, Haute-Garonne, Val d'Oise, etc ...), la Carsat LR, le CHU de Nîmes, APF France Handicap, la Confédération Nationale des Foyers Ruraux (CNFR)
    // ],
    technicalSkills: [
      "Ruby On Rails",
      "MongoDB",
      "VueJS",
      "ElasticSearch",
      "Android (Java / Kotlin)",
      "iOS (Swift)",
      "DevOps",
      "RSpec",
      "FactoryBot",
      "Jest",
    ],
    client: {
      name: "Appliserv - Environnement Mobile",
      url: "https://www.ammy.fr/communautes/lequipe-appliserv",
    },
    date: "septembre 2016 — août 2022",
    url: "https://www.ammy.fr/accueil",
    androidUrl: "https://play.google.com/store/apps/details?id=com.em.ammy",
    iosUrl:
      "https://apps.apple.com/fr/app/ammy-communaut%C3%A9-butterlink/id1290740915",
    image: require("../assets/images/ammy.svg"),
  },
  ammyGenerations: {
    title: "Ammy Générations",
    shortDescription:
      "Plateforme éthique d'échange de services entre générations",
    url: "https://www.ammy.fr/generations",
    image: require("../assets/images/ammyGenerations.svg"),
    functionalDescription: `
<p class="mb-1">
  Ammy générations est une platforme d'échange de service portée par Appliserv / Environnement. <br/>
  Il vise les particuliers, utilisateurs de la communauté Ammy, et leurs permet en premier lieu l'échange de service simple comme la présence à domicile, le gardienage, le transport intra-urbain ou les petites courses.
  Le but de la plateforme est de géolocaliser les annonces des utilisateurs afin de leur proposer les annonces les plus proches de chez eux, correspondant à leurs critères de recherche.
  Enfin, une des fonctionnalité les plus interressante fut proposé dans le cadre d'un abonnement premium nommé "Cercles d'Ammy" permettant à des communauté de créer des annonces Ammy Générations
  et de suggérer des candidats potentiels à leurs membres.
</p>
`,
    technicalDescription: `
<p class="mb-1">
<ul>
<li>
Application socle monolithique (sur le même socle que Ammy Communauté) dévelopée en Ruby On Rails qui sert les données en API REST
</li>
<li>SPA VueJS</li>
<li>Deux applications natives Android / iOS.
</li></ul>
</p>
    `,
    // partners: [
    //   "HelloAsso", une dizaine de département français (Gard, Haute-Garonne, Val d'Oise, etc ...), la Carsat LR, le CHU de Nîmes, APF France Handicap, la Confédération Nationale des Foyers Ruraux (CNFR)
    // ],
    technicalSkills: [
      "Ruby On Rails",
      "MongoDB",
      "VueJS",
      "ElasticSearch",
      "Android (Java / Kotlin)",
      "iOS (Swift)",
      "DevOps",
      "RSpec",
      "FactoryBot",
      "Jest",
    ],
    client: {
      name: "Appliserv - Environnement Mobile",
      url: "https://www.ammy.fr/communautes/lequipe-appliserv",
    },
    date: "mars 2018",
    androidUrl: "https://play.google.com/store/apps/details?id=com.em.ammy",
    iosUrl:
      "https://apps.apple.com/fr/app/ammy-communaut%C3%A9-butterlink/id1290740915",
  },
  butterlink: {
    title: "Butterlink",
    shortDescription: "Messagerie instantanée éthique et (éco)responsable",
    functionalDescription: `
    <p class="mb-1">
Se voit comme une alternative aux messageries des GAFAMS : WhatsApp, Messenger, Google Duo / Meet, ...etc.
Cette application permet de faire ce que fait la plus part des applications de messagerie temps réel aujourd'hui : échange des messages, audio, photos, vidéos, visio-conférence.
Elle a cependant un gros plus pour la planète, elle supprime vos données échangés par défaut au bout d'un lapse de temps paramétrable.
Il vous incombe donc sauvegarder le contenu que vous souhaitez garder dans vos Ammy Docs, tout cela dans le but de ne pas remplir des serveurs avec des données inutiles.
    </P>`,
    technicalDescription: `
    <p class="mb-1">
<ul>
<li>
Application Ruby On Rails / MongoDB qui sert les données en API REST
<li>Application React Native web déployé sur Netlify</li>
<li>Applications React Native Android / iOS.
</li></ul>
</p>
    `,
    date: "avril 2019 à juin 2021",
    url: "https://www.butterlink.fr",
    image: require("../assets/images/butterlink.svg"),
    androidUrl:
      "https://play.google.com/store/apps/details?id=com.em.butterlink",
    iosUrl: "https://apps.apple.com/us/app/butterlink/id1578243890",
    client: {
      name: "Appliserv - Environnement Mobile",
      url: "https://www.ammy.fr/communautes/lequipe-appliserv",
    },
  },
  ammyDocs: {
    title: "Ammy Doc",
    shortDescription: "Plateforme de stockage et de partage de documents",
    functionalDescription: `
    <p class="mb-1">
    C'est le Google Drive d'Ammy Communautés ! Elle permet de sauvegarder et de partager des fichiers entre utilisateurs et communautés, à hauteur de 2GB par compte.
    <br/>
    Elle est également connecté à Butterlink et permet la sauvegarde des messages et du contenu échangés.
    </p>
    `,
    technicalDescription: `
    <p class="mb-1">
    <ul>
    <li>
    Application socle monolithique dévelopée en Ruby On Rails qui sert les données en API REST
    </li>
    <li>SPA React JS</li></ul>
    </p>
    `,
    url: "https://docs.ammy.fr",
    image: require("../assets/images/ammy-docs.svg"),
    androidUrl:
      "https://play.google.com/store/apps/details?id=com.em.butterlink",
    iosUrl: "https://apps.apple.com/us/app/butterlink/id1578243890",
    date: "août 2019",
    client: {
      name: "Appliserv - Environnement Mobile",
      url: "https://www.ammy.fr/communautes/lequipe-appliserv",
    },
  },
  appliserv: {
    title: "Appliserv",
    functionalDescription: `
    <p class="mb-1">
    Plateforme de suivi d'intervention pour professionnels de l'aide à la personne et de l'hospitalisation à domicile.
    Elle peut jouer le rôle primaire d'ERP mais elle permet également de géolocalisé les interventions des intervenants et d'assurer les suivis qualité de chaque mission, avec notamment note et signature client.
    Elle a également un outil d'aide la décision qui permet de gérer les remplacements et les problèmes habituels de planification que l'on retrouve dans ce
    genre de structures victime de turn over et de manque de personnel. <br/>
    Le projet a été arrêté faute de moyens financiers suffisant. L'application n'est donc plus maintenue et indisponible.
    </p>
    `,
    technicalDescription: `

<p class="mb-1">
<ul>
<li>
Application monolythique Ruby On Rails / MongoDB pure
</li>
<li>Application cordova Android / iOS qui permet le suivi d'intervention par les intervenants
</li></ul>
</p>
`,
    shortDescription:
      "Application de suivi d'intervention et d'aide à la décision",
    image: require("../assets/images/appliserv.svg"),
    url: "http://appliserv.fr",
    date: "mars 2014 - septembre 2020",
    client: {
      name: "Appliserv - Environnement Mobile",
      url: "https://www.ammy.fr/communautes/lequipe-appliserv",
    },
  },
};
