<template>
  <v-app :class="readyClass">
    <AppBar />
    <v-main class="pt-0">
      <router-view></router-view>
      <LffFooter></LffFooter>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar";
import LffFooter from "./components/LffFooter.vue";
export default {
  name: "App",
  components: {
    AppBar,
    LffFooter,
  },

  data: () => ({
    readyClass: null,
    //
  }),
  mounted() {
    setTimeout(() => (this.readyClass = "ready"), 0);
  },
};
</script>
