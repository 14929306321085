<template>
  <v-container class="wrapper mt-10 pt-10 px-4">
    <h1 class="mb-2">Conditions Générales de Vente</h1>
    <h2>Article 1 – Parties au contrat</h2>
    <p>
      Le terme client désigne toute personne morale ou physique, ayant requis
      les compétences de La Fab Factory (SIRET : 535 151 070) pour toute création
      dans le cadre de compétences de développeur web, mobile, créations
      graphiques, SEO et gestion de projet. Le terme La Fab Factory désigne le
      prestataire. Le terme tiers désigne toute personne physique ou morale non
      partie au contrat.
    </p>
    <h2>Article 2 – Objet du contrat</h2>
    <p>
      Le contrat établi les relations commerciales entre La Fab Factory et le
      client.
    </p>
    <h2>Article 3 – Relations contractuelles</h2>
    <p>
      Aucun contact téléphonique, ni rendez-vous, ne vaut engagement ; les
      demandes de prestations complémentaires doivent être présentées par le
      client au prestataire (La Fab Factory) par email ou courrier. Les
      obligations entre les parties naissent de la signature d’un bon de
      commande émis par le client ou du retour daté et signé du devis et /ou la
      facture que le prestataire aura transmis au client accompagné de son
      premier acompte. Le prestataire ne débutera aucun développement avant la
      réception de ces documents ; cette disposition est un élément essentiel du
      contrat.
    </p>

    <h2>Article 4 – Traitement et envoi des fichiers par le client</h2>
    <p>
      L’ensemble des fichiers client (textes, polices et images, même issus de
      tiers, en libres de droits, structure et / ou modèle de données) doivent
      être transmis au prestataire avant le début du développement. Les textes
      sont à fournir sous format électronique et typographié sans fautes
      d’orthographe ; aucune saisie de texte ne sera réalisée. La Fab Factory ne
      peut en aucun cas être tenu pour responsable des fautes d’orthographe
      présentes dans les textes fournis par le client.
    </p>
    <h2>Article 5 – Modification de la demande initiale du client</h2>
    <p>
      Tout devis signé par le client sera associé à une description précise du
      rendu attendu par le client. Toute évolution du rendu attendu par le
      client fera l’objet d’un nouveau devis. Si les modifications ou reprises
      des développements prévus dans votre devis engendrent une intervention :
      dont la durée est manifestement excessive par rapport au délai normalement
      nécessaire pour le développement, dont la nature tend à modifier de
      manière significative les développements proposés dans le devis, nécessite
      le traitement des sources afin de les rendre exploitables pour réaliser le
      développement ou le paramétrage du développement (notamment pour
      l’insertion de données dans une base de données), une facturation
      journalière, sur la base de 350,00 euros HT / jour, sera établie. Les
      modifications significatives, reprises de développement, retard dans la
      prise de décision ou livraison tardive de documents par le client et
      nécessaires au développement, repoussent d’autant le délai de livraison
      établi entre les parties.
    </p>
    <h2>Article 6 - Cession des droits</h2>
    <p>
      La cession des droits d’utilisation et de modification des développements
      réalisés par le prestataire s’effectue à complet encaissement des montants
      facturés sauf dispositions contraires mentionnées sur le devis et/ou la
      facture . Cette cession sera détaillée et précisée au cas par cas sur
      chaque devis et/ou facture en fonction de ce qui aura été défini par les
      deux parties. Sauf mention contraire précisée sur chaque devis et/ou
      facture, le règlement complet de la facture n'entraîne que la cession des
      droits d’utilisation et de modification et en aucun cas le droit de
      diffusion du développement.
    </p>
    <h2>Article 7 – Conditions de règlement</h2>
    <p>
      Les développements doivent être réglés au prestataire aux dates
      mentionnées sur le devis et/ou la facture client. Le client s’engage à
      respecter des dates de paiement ; cette disposition est aussi un élément
      essentiel du contrat.
    </p>
    <h2>Article 8 – Recouvrement des créances</h2>
    <p>
      En cas de non paiement, l’ensemble des frais de recouvrement sera à la
      charge du client. Si nécessaire, le prestataire pourra faire appel aux
      services d’une société d’affacturage pour le recouvrement de ses créances
      clients.
    </p>
    <h2>Article 9 – La force majeure</h2>
    <p>
      Les parties ne peuvent être considérées comme responsables ou ayant
      faillis à leurs obligations contractuelles, lorsque le défaut d’exécution
      des obligations respectives a pour origine la force majeure ; le contrat
      entre les parties est suspendu jusqu’à l’extinction des causes ayant
      engendrées la force majeure. La force majeure prend en compte des faits ou
      circonstances irrésistibles, extérieurs aux parties, imprévisibles et
      indépendants de la volonté des parties, malgré tous les efforts
      raisonnablement possibles pour les empêcher. Sont aussi considérés comme
      cas de force majeure, le blocage des moyens de transports ou
      d’approvisionnements, tremblements de terre, incendies, tempêtes,
      inondations, foudre, l’arrêt des réseaux de télécommunication, et
      notamment tous les réseaux accessibles par internet, ou difficultés
      propres aux réseaux de télécommunication extérieurs aux parties. La partie
      touchée par la force majeure en avisera l’autre dans les cinq (5) jours
      ouvrables suivant la date à laquelle elle en aura eu connaissance. Les
      deux parties conviendront alors des conditions dans lesquelles l’exécution
      du contrat sera poursuivie.
    </p>
    <h2>Article 10 – Les conditions générales de vente</h2>
    <p>
      Le prestataire peut modifier, ajouter ou retrancher des dispositions à ces
      conditions générales de vente sans préavis et sans avoir à en aviser ses
      clients ou des tiers préalablement.
    </p>
    <h2>Article 11 – Règlement des litiges</h2>
    <p>
      Le contrat est soumis au droit Français. En cas de litiges, les parties
      s’engagent à tout faire pour régler leurs différends à l’amiable. Au cas
      où une résolution amiable ne pourrait aboutir, la juridiction compétente
      est celle de Nîmes.
    </p>
  </v-container>
</template>

<script>
export default {
  name: "LffCgv",
};
</script>

<style></style>
