<template>
  <div class="projects py-8 d-flex justify-center align-center">
    <v-container class="narrowed">
      <v-row>
        <v-col cols="12">
          <div class="text-center">
            <h2 class="text-h6 font-weight-light text-gradient">
              quelques-uns des
              <span class="text-h1 mt-n3 font-weight-bold text-gradient">
                projets terminés
              </span>
            </h2>
          </div>
        </v-col>
        <v-col cols="12" class="d-flex justify-center align-center my-4">
          <v-row class="d-flex align-center justify-center">
            <v-col
              v-masonry-tile
              cols="12"
              md="4"
              v-for="(project, projectKey) in projects"
              :key="projectKey"
            >
              <v-card
                flat
                class="lff-card d-flex flex-column"
                @click="openProjectDialog(projectKey)"
              >
                <div class="lff-top-image text-center pt-6">
                  <v-img
                    :src="project.image"
                    class="ml-auto mr-auto"
                    width="80"
                    height="80"
                    :alt="`Photo du projet ${project.title}`"
                  />
                </div>
                <v-card-title class="d-flex justify-center align-center">
                  <h3 class="text-h5 text-center">
                    {{ project.title }}
                  </h3>
                </v-card-title>

                <v-card-text
                  class="d-flex flex-column flex-grow-1 justify-center align-center"
                >
                  <div class="text-center">
                    {{ project.shortDescription }}
                  </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-center align-center pb-5">
                  <v-btn
                    elevation="0"
                    color="white"
                    @click="openProjectDialog(projectKey)"
                  >
                    <v-icon left>mdi-plus</v-icon>
                    DÉCOUVRIR</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <LffProject
      v-if="selectedProjectKey"
      ref="projectDialog"
      :project-key="selectedProjectKey"
    />
  </div>
</template>

<script>
import { projects } from "../../data/projects";
import LffProject from "./LffProject.vue";
export default {
  name: "LffProjects",
  computed: {
    projects() {
      return projects;
    },
  },
  components: { LffProject },
  data() {
    return {
      selectedProjectKey: null,
    };
  },
  methods: {
    openProjectDialog(projectKey) {
      this.selectedProjectKey = projectKey;

      // Wait til <LffProject> component is mounted
      this.$nextTick(() => {
        this.$refs.projectDialog.open();
      });
    },
  },
};
</script>

<style></style>
