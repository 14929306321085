<template>
  <div class="hard-skills py-8 d-flex justify-center align-center">
    <v-container class="narrowed">
      <v-row>
        <v-col cols="12">
          <div class="text-left text-sm-right">
            <h2 class="text-h6 font-weight-light text-gradient">
              mes
              <span class="text-h1 mt-n5 font-weight-bold text-gradient"
                >compétences</span
              >
            </h2>
          </div>
        </v-col>
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-row>
            <v-col
              v-scroll-reveal.reset
              cols="12"
              md="4"
              v-for="(skillCategory, skillCategoryKey, i) in hardSkills"
              v-animate-css.once="animationFor(i)"
              :key="skillCategoryKey"
            >
              <v-card flat class="lff-card d-flex flex-column"
                ><v-card-text
                  class="d-flex flex-column flex-grow-1 justify-center align-center"
                >
                  <div
                    class="d-flex skills flex-wrap justify-center align-center"
                  >
                    <v-chip
                      v-for="(skill, j) in skillCategory"
                      :key="`skill-${j}`"
                      v-text="skill"
                    ></v-chip>
                  </div>
                </v-card-text>
                <v-card-title class="d-flex justify-center align-center">
                  <h3 class="text-h5 text-center">
                    {{ skillCategoryKey }}
                  </h3>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { hardSkills } from "../../data/hard-skills";
export default {
  name: "LffHardSkills",
  computed: {
    hardSkills() {
      return hardSkills;
    },
  },
  methods: {
    animationFor(index) {
      return index % 2 == 0 ? "slideInDown" : "slideInUp";
    },
  },
};
</script>

<style lang="scss" scoped>
.skills {
  gap: 4px;
}
.lff-card {
  min-height: 320px;
}
</style>
