<template>
  <div class="text-center mb-10 mt-8 z-index-reset">
    <h3 class="text-h1">
      <span class="font-weight-bold text-gradient"> convaincu ? </span>
      <span class="text-h6 mt-n2 font-weight-light text-gradient">
        ... alors n'hésitez-pas
      </span>
    </h3>

    <v-btn
      href="https://calendly.com/la-fab-factory/30min"
      x-large
      color="primary"
      class="mb-10 mt-4"
      elevation="0"
      target="_blank"
      >PRENEZ RENDEZ-VOUS</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "LffPreFooter",
};
</script>

<style lang="scss" scoped></style>
