<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card class="grey darken-4 white--text text--darken-2 position-relative">
      <v-card-title class="title">
        <v-img
          :alt="`Photo du projet ${project.title}`"
          :src="project.image"
          class="mr-2"
          max-width="30"
        />
        {{ project.title }}
        <v-btn dark absolute right icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="white--text text--darken-2">
        <div>
          <span class="font-weight-bold">Description fonctionnelle : </span>
          <div v-html="project.functionalDescription"></div>
        </div>
        <div>
          <span class="font-weight-bold">Description technique : </span>
          <div v-html="project.technicalDescription"></div>
        </div>
        <div v-if="project.date" class="mt-4">
          <span class="font-weight-bold">Date : </span>
          {{ project.date }}
        </div>
        <div v-if="project.client" class="mt-4">
          <span class="font-weight-bold">Client : </span>
          <a :href="project.client.url" target="_blank">{{
            project.client.name
          }}</a>
        </div>
        <div class="technical-skills mt-4" v-if="project.technicalSkills">
          <div class="font-weight-bold mb-2">Technologies :</div>
          <div class="skills-chips d-flex flex-row flex-wrap">
            <v-chip
              v-for="technicalSkill in project.technicalSkills"
              :key="technicalSkill"
              class="mr-2"
            >
              {{ technicalSkill }}
            </v-chip>
          </div>
        </div>
      </v-card-text>
      <v-card-actions
        class="actions z-index-reset pb-4 d-flex flew-row flex-wrap justify-center justify-sm-start"
        v-if="project.url || project.androidUrl || project.iosUrl"
      >
        <v-btn
          v-if="project.iosUrl"
          elevation="0"
          :href="project.iosUrl"
          target="_blank"
        >
          <v-icon left>mdi-apple</v-icon>
          AppStore
        </v-btn>
        <v-btn
          v-if="project.androidUrl"
          elevation="0"
          :href="project.androidUrl"
          target="_blank"
        >
          <v-icon left>mdi-google-play</v-icon>
          Google PlayStore
        </v-btn>
        <v-btn
          v-if="project.url"
          elevation="0"
          :href="project.url"
          target="_blank"
        >
          <v-icon left>mdi-link</v-icon>
          Site web</v-btn
        >
      </v-card-actions>

      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import { projects } from "../../data/projects";
export default {
  name: "lff-project",
  props: {
    projectKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    project() {
      return projects[this.projectKey];
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.technical-skills {
  .skills-chips {
    gap: 5px;
  }
}
.actions {
  gap: 15px;
}
.title {
  position: sticky;
  top: 0;
  z-index: 200;
  background: #212121;
}
</style>
