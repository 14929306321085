<template>
  <div class="lff-pentagone">
    <div class="about-me lff-wrapper pt-8 d-flex justify-center align-center">
      <v-container class="narrowed">
        <v-row>
          <v-col cols="12">
            <div class="text-right">
              <h2 class="text-h6 whitish--text font-weight-light">
                et mon
                <span class="text-h1 mt-n5 whitish--text font-weight-bold"
                  >parcours</span
                >
              </h2>
            </div>
          </v-col>
          <v-col cols="12" class="text-center pb-10">
            <div class="about-wrapper">
              <div class="diploma">
                <v-img
                  contain
                  eager
                  :src="require('../../assets/images/diploma.svg')"
                  height="200"
                  width="263"
                  alt="Illustration d'un diplôme"
                />
              </div>
              <div class="quote">
                Ingénieur diplomé en septembre 2012 de l’École des Mines d’Alès,
                spécialisé en programmation informatique, j’ai aujourd’hui 10
                ans d’expérience professionnelle en développement fullstack web
                & mobile. J’ai aussi eu l’occasion d'encadrer de petites équipes
                de développeurs et de mener à bien un grand nombre de projets
                numériques complexes.
              </div>
            </div>
            <div class="py-8 d-flex align-center justify-center">
              <v-btn
                title="CV de David Fabreguette"
                href="/cv.pdf"
                target="_blank"
                large
                elevation="0"
              >
                <v-icon left>mdi-shield-star</v-icon>
                VOIR MON CV</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "LffAboutMe",
};
</script>

<style lang="scss" scoped>
.about-wrapper {
  position: relative;
  display: inline-block;
  .quote {
    background: url("../../assets/images/quote.svg") no-repeat center;
    width: 470px;
    height: 352px;
    background-size: cover;
    padding-top: 88px;
    padding-left: 80px;
    padding-right: 53px;
    color: white;
    font-weight: bold;
    user-select: none;
    margin-right: 233px;
  }
  .diploma {
    position: absolute;
    right: 20px;
    top: 100px;
  }
}

@media (min-width: 658px) and (max-width: 860px) {
  .about-wrapper {
    .diploma {
      display: block;
      left: 20px;
      top: 0px;
    }
  }
}
@media (max-width: 860px) {
  .about-wrapper {
    position: relative;
    display: block;
    text-align: right;
    .quote {
      background: none;
      width: 100%;
      max-width: 350px;
      text-align: right;
      height: auto;
      background-size: cover;
      padding: 5px !important;
      color: white;
      font-weight: bold;
      user-select: none;
      margin-right: 0px;
      margin-left: auto;
    }
  }
  .diploma {
    display: none;
  }
}
</style>
