import LffLanding from "./components/LffLanding.vue";
import LffMentions from "./components/LffMentions.vue";
import LffCgv from "./components/LffCgv.vue";
export default [
  {
    name: "landing",
    path: "/",
    component: LffLanding,
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: LffMentions,
    meta: {
      title: "Mentions Légales"
    } 
  },
  {
    name: "conditions-generales-de-vente",
    path: "/conditions-generales-de-vente",
    component: LffCgv,
    meta: {
      title: "Conditions Générales de Vente"
    }
  },
];
