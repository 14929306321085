export const services = [
  {
    title: "Web Frontend",
    description: `Intégration HTML / CSS
SEO
Développement web (UX / UI Design)`,
  }, {
    title: "Web Backend",
    description: `Développement backend (API REST / GraphQL)
Architecture de données (MongoDB / MySQL
Optimisation`
  }, {
    title: "Mobile",
    description: `Développement natif (Android / iOS)
Développement hybride (React Native)`
  }, {
    title: "Gestion de projet",
    description: `Suivi de projet (plannification, Agile SCRUM Master, indicateurs KPI)
Pilotage d'équipe technique (accompagnement à distance, formation)`
  }
];
